import { useEffect, useState } from "react";

import { ScreenSize } from "./types";
import { isClient } from "@/utils/browser";

export const useWindowSize = (
  mobileBreakpoint?: number
): ScreenSize | undefined => {
  const defaultBreakPoint = 718;
  const breakPoint = mobileBreakpoint || defaultBreakPoint;

  // Validate window to prevent build Error: React Hook "useState" and "useEffect" is called conditionally. react-hooks/rules-of-hooks
  const hasMobileWidth = isClient() && window.innerWidth < breakPoint;
  const hasTabletWidth =
    isClient() && window.innerWidth > breakPoint && window.innerWidth < 1232;
  const hasDesktopWidth = isClient() && window.innerWidth > 1232;
  const hasSmallDesktopWidth =
    isClient() && window.innerWidth > 940 && window.innerWidth < 1200;

  const [screenSize, setScreenSize] = useState<ScreenSize>({
    isMobile: hasMobileWidth,
    isTablet: hasTabletWidth,
    isDesktop: hasDesktopWidth,
    isSmallDesktop: hasSmallDesktopWidth,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenSize({
        isMobile: hasMobileWidth,
        isTablet: hasTabletWidth,
        isDesktop: hasDesktopWidth,
        isSmallDesktop: hasSmallDesktopWidth,
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return screenSize;
};
