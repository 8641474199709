"use client";
import React, { useContext } from "react";
import { Link } from "../Link";

import Banner from "@/components/Banner";
import { Chip } from "@/components/Chip";
import { Typography } from "@/components/Typography";
import { featuredAIProps } from "@/data/types/home";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import "./styles.scss";

export const AIModule = ({ data }: { data: featuredAIProps }) => {
  const {
    backgroundColor: bg,
    chipLabel,
    ctaLabel,
    ctaLink,
    data_qa,
    imageAlt,
    imageSrc,
    subtitle,
    subtitle2,
    title,
  } = data;

  const { setSource } = useContext(AmplitudeContext);

  return (
    <Banner small light customBgColor={bg}>
      <div className="ai-module">
        <div className="ai-module__header">
          <div className="ai-module__header__title">
            <div className="ai-module__header__title__text">
              <Chip
                label={chipLabel}
                type="filled"
                color="warning"
                className="ai-module__header__title__text__chip"
              />
              <Typography Tag="h2" classname="u-text-center u-spacing-0">
                {title}
              </Typography>
            </div>
          </div>
          <div className="ai-module__header__subtitle">
            <Typography Tag="p" classname="u-text-center">
              {subtitle}
            </Typography>

            {subtitle2 && (
              <Typography Tag="p" classname="u-text-center">
                {subtitle}
              </Typography>
            )}
          </div>
          <div className="ai-module__header__cta">
            <Link
              dataQA={data_qa}
              href={ctaLink}
              className="bold-text large button"
              type="button"
              onClick={() => setSource("cta_steps")}
              nofollow
            >
              {ctaLabel}
            </Link>
          </div>
        </div>
        <div className="ai-module__image">
          <img src={imageSrc} alt={imageAlt} />
        </div>
      </div>
    </Banner>
  );
};
