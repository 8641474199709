"use client";
import classNames from "classnames";
import { Button } from "../Button";
import { CardBoxProps } from "./types";
import { Typography } from "../Typography";
import "./styles.scss";
import { Link } from "../Link";

export const CardBox = ({
  item,
  shadow,
  className,
  amplitudeEvent,
  setSource,
}: CardBoxProps) => {
  const classnames = classNames(`card-box`, {
    ["card-box--shadow"]: shadow,
    [`${className}`]: className,
  });

  return (
    <div className={`${classnames} u-box`}>
      {item.image && (
        <div className="card-box-image-section">
          <img
            className="card-box-image"
            src={item.image.src}
            alt={item.image.alt}
          />
        </div>
      )}
      <div
        className={
          item.image ? "card-box-section--left" : "card-box-section--center"
        }
      >
        <h3 className="card-box-title">{item.title}</h3>
        {item.richtext.map((item, i) => (
          <Typography key={i} Tag={item.tag} classname="card-box-content">
            {item.list
              ? item.list.map((e, i) => (
                  <li key={i}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: e["item"] as string,
                      }}
                    ></span>
                  </li>
                ))
              : item.content}
          </Typography>
        ))}

        {item.actionButton && (
          <Link
            href={item.actionButton.href}
            className="bold-text button button--large card-action-button"
            type="button"
            onClick={() => amplitudeEvent && setSource(amplitudeEvent)}
            nofollow
          >
            {item.actionButton.label}
          </Link>
        )}
      </div>
    </div>
  );
};
