import React from "react";

import Banner from "@/components/Banner";
import { BannerWrapperSize } from "@/components/Banner/types";
import { billboardProps } from "@/data/types/home";
import { Hero } from "../Hero";


export const HeroModule = ({
  data,
  wrapperSize,
}: {
  data: billboardProps;
  wrapperSize: BannerWrapperSize;
}) => {

  const { ctaLabel, ctaLink, richtext, data_qa, backgroundColor } = data;

  return (
    <Banner
      medium
      light
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}>
      <Hero
        heroText={richtext}
        cta={{
          ctaLink,
          ctaLabel,
          data_qa,
        }}
        img={"/images/hero-banner.png"}
      />
    </Banner>
  );
};
