import { previewHome } from "@/data/types/home";
import React from "react";
import { BannerWrapperSize } from "../Banner/types";
import Banner from "../Banner";
import { Typography } from "../Typography";
import { Preview } from "../Preview";

export const GridModule = ({
  data,
  wrapperSize,
}: {
  data: previewHome;
  wrapperSize: BannerWrapperSize;
}) => {
  const { items, subtitle, title, data_qa, backgroundColor } = data;

  return (
    <Banner
      small
      light
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}
      customClass="banner-preview"
    >
      <Typography Tag={"h2"} spacing={4}>
        {title}
      </Typography>
      <Typography Tag={"p"} size="l" spacing={4}>
        {subtitle}
      </Typography>
      <Preview items={items} dataQA={data_qa} />
    </Banner>
  );
};
