"use client";

import React, { useEffect, useRef } from "react";
import { TrustpilotProps } from "./types";
import { isClient } from "@/utils/browser";

import "./styles.scss";

const TrustBoxHorizontal = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef}
    className="trustpilot-widget trustBox-horizontal"
    data-locale="en-US"
    data-template-id="5406e65db0d04a09e042d5fc"
    data-businessunit-id="64f888eb7b782062676ccb0d"
    data-style-height="28px"
    data-style-width="100%"
    data-theme="light"
  >
    <a
      href="https://www.trustpilot.com/review/qrcreator.com"
      target="_blank"
      rel="noopener"
    />
  </div>
);

const TrustBoxCarrousel = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef}
    className="trustpilot-widget trustBox-carrousel"
    data-locale="en-US"
    data-template-id="53aa8912dec7e10d38f59f36"
    data-businessunit-id="64f888eb7b782062676ccb0d"
    data-style-height="140px"
    data-style-width="100%"
    data-theme="light"
    data-stars="5"
    data-review-languages="en"
  >
    <a
      href="https://www.trustpilot.com/review/qrcreator.com"
      target="_blank"
      rel="noopener"
    />
  </div>
);

export const Trustpilot = ({
  testVariant,
  trustpilotLoaded,
}: TrustpilotProps) => {
  const ref = useRef(null);

  useEffect(() => {
    if (trustpilotLoaded)
      isClient() && window.Trustpilot.loadFromElement(ref.current, true);
  }, []);
  return (
    <div className="trustpilot__wrapper">
      {testVariant === "A" ? (
        <TrustBoxHorizontal trustBoxRef={ref} />
      ) : (
        <TrustBoxCarrousel trustBoxRef={ref} />
      )}
    </div>
  );
};
