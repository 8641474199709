
import React from "react";
import { isClient } from "@/utils/browser";



 export const useTrustpilot = () => {

  const [isTrustpilotLoaded, setIsTrustpilotLoaded] = React.useState(false);

  React.useEffect(() => {
    if (isClient() && window.Trustpilot)  setIsTrustpilotLoaded(true)
  }, []);

  return isTrustpilotLoaded;
};

